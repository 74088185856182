import * as React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SSRProvider } from "react-bootstrap";

import ContentQuoteCenter from "../../../../components/contentQuoteCenter";
import DefaultHeader from "../../../../components/default-header";
import Footer from "../../../../components/footer";
import GdprPanel from "../../../../components/gdpr";
import Navigation from "../../../../components/navigation";
import onlineCoursesFAQ from "../../../../components/onlineCoursesFAQ";
import Seo from "../../../../components/seo";

import "../../../../styles/online-courses.scss";
import NewsletterSignup from "../../../../components/newsletterSignUp";

const Page = ({ data }) => {
  const shortcodes = {
    Row,
    Col,
    onlineCoursesFAQ,
    ContentQuoteCenter,
  };

  return (
    <>
      <SSRProvider>
        <Navigation />
        <DefaultHeader
          headline={data.mdx.frontmatter.headline}
          heroBackground={data.mdx.frontmatter.heroBackground}
          heroMobileBackground={data.mdx.frontmatter.heroMobileBackground}
          subHeadline={data.mdx.frontmatter.subheadline}
        />
        <div id="body">
          <Container fluid="lg">
            <Row>
              <Col className="mb-5">
                <Row className="white-bg mb-5" id="introduction">
                  <Col className="p-4">
                    <MDXProvider components={shortcodes}>
                      <MDXRenderer>{data.mdx.body}</MDXRenderer>
                    </MDXProvider>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
        <GdprPanel />
        <NewsletterSignup />
      </SSRProvider>
    </>
  );
};

export const Head = ({ data }) => (
  <Seo
    title={data.mdx.frontmatter.metadata.Title}
    description={data.mdx.frontmatter.metadata.Description}
    pathname={data.mdx.frontmatter.metadata.Link}
    ogImage={data.mdx.frontmatter.metadata.ogImage}
    cardImage={data.mdx.frontmatter.metadata.cardImage}
  />
);

export const query = graphql`
  query SocialArtistry2019PageQuery {
    mdx(fileAbsolutePath: { regex: "/social-artistry.mdx/" }) {
      frontmatter {
        heroBackground
        heroMobileBackground
        subheadline
        headline
        title
        subNav {
          text
          url
        }
        metadata {
          Description
          Keywords
          Link
          Title
          ogImage
          cardImage
        }
      }
      body
    }
  }
`;

export default Page;
